<!-- This is the file that will render any external pages you do not want inside the dash, like login form-->
<template>
  <v-app id="login-home">
    <v-main>
        <div style="width:100vw;height:100%;display:flex;flex-direction:column;justify-content:center;align-items:center;">
                
                <div>
                    <v-select  v-model="tag" :items="tags" item-value="id" item-text="valor" return-object  style="width:80%;max-width:500px;height:50px;margin-bottom:30px;">
                    </v-select>

                    <v-btn small elevation="" @click="direcionar('doc')" color="primary" style="width:80%;max-width:500px;height:50px;margin-bottom:30px;font-weight:bold;" >Captura de Documentos</v-btn>
                    <v-btn small elevation="" @click="direcionar('ass')" color="primary" style="width:80%;max-width:500px;height:50px;margin-bottom:30px;font-weight:bold;" >Captura de Assinatura</v-btn>
                    <v-btn small elevation="" @click="direcionar('ambos')" color="primary" style="width:80%;max-width:500px;height:50px;margin-bottom:30px;font-weight:bold;" >Captura de Ambos</v-btn>
                </div>
            
        </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
    data: () => ({
        idusuario:null,
        modo:"0",
        tag:{},
        tags:[
        {id:1,valor:'TAG 1'},
        {id:2,valor:'TAG 2'},
        {id:3,valor:'TAG 3'},
        {id:4,valor:'TAG 4'},
        {id:5,valor:'TAG 5'},
        {id:6,valor:'TAG 6'},
        {id:7,valor:'TAG 7'},
        {id:8,valor:'TAG 8'},
        {id:9,valor:'TAG 9'},
        {id:10,valor:'TAG 10'},
        {id:11,valor:'TAG 11'},
        {id:12,valor:'TAG 12'},
        {id:13,valor:'TAG 13'},
        {id:14,valor:'TAG 14'},
        {id:15,valor:'TAG 15'},
        {id:16,valor:'TAG 16'},
        {id:17,valor:'TAG 17'},
        {id:18,valor:'TAG 18'},
        {id:19,valor:'TAG 19'},
        {id:20,valor:'TAG 20'}
        ],
    }),
    components:{
       
    },
    methods: {
       direcionar(tipo){
           
           if(this.tag.id){

                localStorage.setItem("tag",this.tag.id)
                if(tipo=='doc'){
                    localStorage.setItem("textoCaptura",' de Documentos')
                    this.$router.push(`/captureLogin/1/1`)
                }
                if(tipo=='ass'){
                    localStorage.setItem("textoCaptura",' de Assinatura')
                    this.$router.push(`/captureLogin/1/2`)
                }

                if(tipo=='ambos'){
                    this.$router.push(`/captureLogin/1/0`)
                }

           }else{
               alert("Escolha uma TAG para Sincronizar")
           }
       }
    },
    mounted() {
        this.idusuario = localStorage.getItem('userid')
        localStorage.setItem('ativarimportacoes', null)
        // localStorage.setItem('hotel', null)
        localStorage.setItem('idioma', null)
        localStorage.setItem('avatar', null)
        localStorage.setItem('nomehotel', null)
        localStorage.setItem('idhotelpms', null)
        localStorage.setItem('user', null)
        localStorage.setItem('useremail', null)
        // localStorage.setItem('x-access-token', null)
        // localStorage.setItem('token', null)
        // localStorage.setItem('userid', null)
        
    },
};
</script>

<style>
</style>
